<template>
  <div class="menu" :class="{ 'menu--collapse': !showLeftMenu }">
    <div class="menu__links">
      <div
        class="menu__link menu__link--menu"
        :class="{ 'menu__link--collapse': !showLeftMenu }"
        @click="leftMenuLoading ? null : toggleLeftMenu(!showLeftMenu)"
      >
        <span>{{ $t("leftMenu.menu") }}</span>
      </div>
      <div class="menu__link menu__link--add" @click="openCustomerModal()">
        {{ $t("leftMenu.new") }}
      </div>
      <router-link
        class="menu__link menu__link--dashboard"
        :to="{ name: 'Dashboard' }"
        v-if="$checkRolePermission('default')"
        >{{ $t(`leftMenu.${"dashboard"}`) }}</router-link
      >
      <router-link
        class="menu__link menu__link--customers"
        :to="{
          name: 'Customers',
        }"
        :class="{
          'router-link-active router-link-exact-active':
            $route.params.entity === 'customer',
        }"
        v-if="$checkRolePermission('customer_access') || $checkRolePermission('customer_show')"
        >{{ $t(`leftMenu.${"customers"}`) }}</router-link
      >

      <router-link
        class="menu__link menu__link--contract"
        :to="{
          name: 'Contracts',
        }"
        :class="{
          'router-link-active router-link-exact-active':
            $route.params.entity === 'contracts',
        }"
        v-if="$checkRolePermission('customer_access') || $checkRolePermission('customer_show')"
        >{{ $t(`leftMenu.${"contracts"}`) }}</router-link
      >


      <router-link
        class="menu__link menu__link--users"
        :to="{
          name: 'Users',
          query: {
            page: 1,
          },
        }"
        :class="{
          'router-link-active router-link-exact-active':
            $route.params.entity === 'user',
        }"
        v-if="$checkRolePermission('user_access')"
        >{{ $t(`leftMenu.${"users"}`) }}</router-link
      >
      <router-link
        class="menu__link menu__link--potential"
        :to="{
          name: 'PotentialCustomers',
        }"
        :class="{
          'router-link-active router-link-exact-active':
            $route.params.entity === 'potential-customer',
        }"
        v-if="$checkRolePermission('customer_access')"
        >{{ $t(`leftMenu.${"potentialCustomers"}`) }}</router-link
      >
      <router-link
        class="menu__link menu__link--contact"
        :to="{ name: 'Contacts' }"
        v-if="$checkRolePermission('company_access')"
        >{{ $t(`leftMenu.${"contacts"}`) }}</router-link
      >
      <router-link
        class="menu__link menu__link--report"
        :to="{ name: 'Reports' }"
        v-if="$checkRolePermission('report_access')"
        >{{ $t(`leftMenu.${"report"}`) }}</router-link
      >
    </div>

    <div class="menu__links menu__links--bottom">
      <router-link
        :to="{ name: 'Settings' }"
        class="menu__link menu__link--settings"
        v-if="$checkRolePermission('settings_access')"
        :class="{
          'router-link-active link--settings router-link-exact-active':
            $route.name === 'PriceCategories' || $route.name === 'UserRights',
        }"
        >{{ $t("leftMenu.settings") }}</router-link
      >
      <div class="menu__logo">
        <img src="../assets/icons/default/logo_main_left_menu.svg" alt="" />
      </div>

      <a
        href="https://forms.monday.com/forms/embed/5dbc34a77e32bedda6a7e0886a6a6529?r=use1"
        target="_blank"
        class="support button button--default button--shadow"
        :class="{ 'support--collapse': !showLeftMenu }"
      >
        <div class="support__icon">
          <img src="../assets/icons/default/Bold 2px-headphone.svg" alt="" />
        </div>
        <span class="support__title">{{ $t(`leftMenu.${"support"}`) }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "default",

  props: {
    showLeftMenu: {
      required: true,
      type: Boolean,
    },

    toggleLeftMenu: {
      required: true,
      type: Function,
    },

    leftMenuLoading: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(["showAddCustomerModal"]),
  },

  methods: {
    openCustomerModal() {
      this.$store.commit("setIsAddingCustomer", false);
      this.$store.commit("setIsAddingMember", false);
      this.$store.commit("setShowAddCustomerModal", true);
    },
  },
};
</script>

<style scoped lang="scss">
$linkHeight: 50px;

.menu {
  width: 300px;
  overflow: hidden;
  transition: width 0.2s ease;
  position: relative;
  user-select: none;
  box-shadow: 0px 3px 26px var(--shadow-1);
  z-index: 1;
  background: var(--bg-1);

  &--collapse {
    width: 70px;
  }

  &__link {
    padding-left: 66px;
    height: $linkHeight;
    display: flex;
    align-items: center;
    color: var(--text-2);
    font-size: var(--medium);
    transition: all 0.2s ease;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    font-family: var(--muli-text-semi-bold);
    border-left: 4px solid transparent;

    &--dashboard {
      background: url("../assets/icons/default/dashboard_icon.svg");
    }
    &--customers {
      background: url("../assets/icons/default/customers_icon.svg");
    }
    &--users {
      background: url("../assets/icons/default/users_icon.svg");
    }
    &--potential {
      background: url("../assets/icons/default/potentialcustomer_icon.svg");
    }
    &--contact {
      background: url("../assets/icons/default/contact_icon_left_menu.svg");
    }
    &--contract {
      background: url("../assets/icons/default/contract_icon.svg");
    }
    &--report {
      background: url("../assets/icons/default/report_icon.svg");
    }
    &--settings {
      background: url("../assets/icons/default/settings_icon.svg");
    }
    &--menu {
      background: url("../assets/icons/default/menu_icon.svg");
    }
    &--collapse {
      background: url("../assets/icons/default/menu_icon_colapse.svg");
    }

    &--add {
      color: var(--text-5);
      position: relative;

      &:after,
      &:before {
        position: absolute;
        transition: all 0.2s ease-in-out;
      }

      &:before {
        z-index: -1;
        content: "";
        display: block;
        width: 65px;
        height: 100%;
        left: -4px;
        border-radius: 0 var(--radius-1) var(--radius-1) 0;
        background-color: var(--bg-2);
      }

      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        left: 20px;
        background-image: url("../assets/icons/default/add_icon.svg");
      }

      &:hover {
        color: var(--text-1);

        &:before {
          width: calc(100% + 4px);
        }

        &:after {
          transform: rotate(180deg);
        }
      }
    }

    &--add,
    &--menu span {
      font-family: var(--muli-text-bold);
    }

    &--menu {
      height: 60px;
    }

    &--dashboard.router-link-active {
      background: url("../assets/icons/default/dashboard_icon_hover.svg");
    }
    &--customers.router-link-active {
      background: url("../assets/icons/default/customers_icon_hover.svg");
    }
    &--users.router-link-active {
      background: url("../assets/icons/default/users_icon_hover.svg");
    }
    &--potential.router-link-active {
      background: url("../assets/icons/default/potentialcustomer_icon_hover.svg");
    }
    &--contact.router-link-active {
      background: url("../assets/icons/default/contact_icon_hover.svg");
    }
    &--report.router-link-active {
      background: url("../assets/icons/default/report_icon_hover.svg");
    }
    &--settings.router-link-active {
      background: url("../assets/icons/default/settings_icon_hover.svg");
    }

    &--dashboard,
    &--customers,
    &--users,
    &--potential,
    &--contact,
    &--contract,
    &--report,
    &--add,
    &--settings,
    &--email,
    &--web,
    &--phone,
    &--menu,
    &--dashboard.router-link-active,
    &--menu.router-link-active,
    &--customers.router-link-active,
    &--users.router-link-active,
    &--potential.router-link-active,
    &--contact.router-link-active,
    &--report.router-link-active,
    &--settings.router-link-active {
      background-size: 24px;
      background-position: 20px;
      background-repeat: no-repeat;
    }
  }

  &__links {
    position: relative;
    &--bottom {
      position: absolute;
      bottom: 0;
      width: 100%;

      .menu__link {
        padding-left: 0;
        justify-content: center;

        &--settings {
          padding-left: 70px;
          justify-content: initial;
        }

        // &--settings {
        //   border-top: 1px solid var(--border-5);
        //   border-bottom: 1px solid var(--border-5);
        // }
      }
    }
  }

  &__logo {
    width: 35%;
    margin: 40px auto;
  }

  .router-link-active,
  &__link:hover:not(.menu__link--menu):not(.menu__link--add) {
    color: var(--text-5);
    border-left-color: var(--border-2);
    background-color: var(--highlight-1);
  }

  .support {
    margin: 0 auto;
    height: 48px;
    width: 65%;
    padding: 0;
    position: relative;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      position: absolute;
      top: 50%;
      left: 26px;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
    }

    &__title {
      font-family: var(--muli-text-semi-bold);
    }

    &__icon,
    &__title {
      transition: all 0.2s ease-in-out;
    }

    &--collapse {
      .support__icon {
        left: 50%;
      }

      .support__title {
        opacity: 0;
      }
    }
  }
}
</style>
