<template>
<div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--service">
        <div class="modal__logo-wrapper">
            <div class="modal__logo"></div>
            <div class="modal__logo-title">The One System</div>
        </div>

        <div class="modal__main-icon modal__main-icon--policy"></div>
        <div class="modal__back" @click="goBack()"></div>
        <div class="modal__close" @click="closeModal()"></div>

        <div class="form">
            <div class="navigators">
                <div class="navigator navigator--active">
                    <div class="navigator__bullet"></div>
                    <div class="navigator__title">
                        {{ $t(`addPolicyModal.${"title"}`) }}
                    </div>
                </div>
            </div>

            <form v-if="teamId == null" class="add__form add__form--service">
                <transition name="fade" mode="out-in">
                    <Loading v-if="formLoading" />
                    <div v-else class="add__content">
                        <div class="policy">
                            <div class="policy__form">
                                <div class="policy__inputs">
                                    <div class="policy__column">
                                        <div class="form__field">
                                            <VSelect 
                                                class="select select--default" 
                                                placeholder="Teams" 
                                                :class="{'select--error': teamIdHasError }" 
                                                :options="fetchedTeams" 
                                                :reduce="(team) => team.id" 
                                                :getOptionLabel="
                                                (team) => `${team.name}`
                                                " 
                                                v-model="teamId" 
                                                @input="fetchPolicyData(teamId)"
                                            />
                                            <div class="form__error" v-if="teamIdHasError">
                                                Please select a team.
                                            </div>
                                        </div>

                                        <div class="form__field disabled-el">
                                            <VSelect 
                                              disabled 
                                              class="select select--default" 
                                              placeholder="Agents"
                                            /> 
                                        </div> 
                                        <div class="form__field disabled-el">
                                            <VSelect
                                              disabled 
                                              class="select select--default"  
                                              placeholder="Firma" 
                                            />
                                            
                                        </div>
                                        <div class="form__field disabled-el">
                                            <input disabled type="text" class="input input--default" placeholder="Policy Nr." /> 
                                        </div>

                                    </div>
                                    <div class="policy__column">
                                        <div class="form__field disabled-el">
                                            <VSelect disabled class="select select--default"  placeholder="Berater"/>
                                        </div>
                                        <div class="form__field disabled-el">
                                            <div class="mandat">
                                                <label class="mandat__title" for="mandatCheckbox">Makler Mandat</label>
                                                <div class="checkbox">
                                                    <input disabled type="checkbox" id="mandatCheckbox" class="checkbox__checkbox" />
                                                    <label for="mandatCheckbox" class="checkbox__label"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form__field disabled-el">
                                            <VSelect disabled class="select select--default" placeholder="Broker Firma" />  
                                        </div>
                                        <div class="form__field disabled-el">
                                            <VSelect disabled class="select select--default" placeholder="Status"/>
                                        </div>
                                    </div>
                                </div>

                                <DropFile class="disabled-el" :disabled="true" @input="files = $event" />
                            </div> 
                        </div>
                    </div>
                </transition>
            </form>

            <form v-else class="add__form add__form--service">
                <transition name="fade" mode="out-in">
                    <Loading v-if="formLoading" />
                    <div v-else class="add__content">

                        <div class="policy">
                            <div class="policy__form"> 
                                <div class="policy__inputs"> 
                                    <div class="policy__column">
                                        <div class="form__field"> 
                                            <div class="input input--default input--readonly">
                                                {{ customerDetails.team.name }}
                                            </div> 
                                        </div>


                                        <div class="form__field">
                                            <VSelect v-if="customerMembers.length" :class="{ disable: teamId == null }" class="select select--default" :options="customerMembers" :reduce="(member) => member.id" :getOptionLabel="
                            (member) => parseCustomerName(member)
                          " v-model="policy.customerId" />
                                            <div v-else class="input input--default input--readonly">
                                                {{ parseCustomerName(customerDetails) }}
                                            </div>
                                        </div>
                                    
                                        <div class="form__field">
                                            <VSelect :class="{ disable: teamId == null, 'select--error': companyHasError }" class="select select--default" :options="companies" :reduce="(company) => company.id" v-model="companyId" label="name" placeholder="Firma" />
                                            <div class="form__error" v-if="companyHasError">
                                                {{ $t(`addPolicyModal.${"companyErrorMsg"}`) }}
                                            </div>
                                        </div>
                                        <div class="form__field">
                                            <input :class="{ disable: teamId == null }" type="text" class="input input--default" placeholder="Policy Nr." v-model="policy.policyNumber" />
                                            <div class="form__error" v-if="policyNumberHasError">
                                                {{ policyNumberErrorPlaceHolder }}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="policy__column">
                                        <div class="form__field">
                                            <VSelect 
                                              :class="{'select--error': agentHasError }" 
                                              :options="agents" 
                                              :reduce="(agent) => agent.id" 
                                              class="select select--default"  
                                              placeholder="Berater" 
                                              v-model="policy.agentId" 
                                              :getOptionLabel="(agent) => `${agent.first_name} ${agent.last_name}`" 
                                            />
                                            <div class="form__error" v-if="agentHasError">
                                                {{ $t(`addPolicyModal.${"agentErrorMsg"}`) }}
                                            </div>
                                        </div>
                                        <div class="form__field">
                                            <div class="mandat">
                                                <label class="mandat__title" for="mandatCheckbox">Makler Mandat</label>
                                                <div class="checkbox">
                                                    <input :class="{ disable: teamId == null }" type="checkbox" id="mandatCheckbox" class="checkbox__checkbox" v-model="enableBrokerFirma" />
                                                    <label for="mandatCheckbox" class="checkbox__label"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form__field">
                                            <VSelect :class="{ disable: teamId == null }" v-if="enableBrokerFirma" class="select select--default" :options="brokerCompanies" :reduce="(company) => company.id" label="name" placeholder="Broker Firma" v-model="policy.policyMandate.companyId" />
                                            <div class="select select--default select--readonly" v-else>
                                                Broker Firma
                                            </div>
                                        </div>
                                        <div class="form__field">
                                            <VSelect :class="{ disable: teamId == null, 'select--error': statusHasError  }" :options="policyStatuses" :reduce="(policy) => policy.id" label="name" class="select select--default"   placeholder="Status" v-model="policy.policyStatusId" />
                                            <div class="form__error" v-if="statusHasError">
                                                {{ $t(`addPolicyModal.${"policyStatusErrorMsg"}`) }}
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>

                                <DropFile @input="files = $event" />
                            </div>

                            <div v-if="showServices" class="policy__service-toggler">
                                <span>Services</span>
                                <div class="policy__service-adder" :class="{
                      'policy__service-adder--active': showServicesDropdown,
                    }" @click="showServicesDropdown = !showServicesDropdown"></div>

                                <transition name="fade">
                                    <div class="service-dropdown" v-if="showServicesDropdown">
                                        <Loading v-if="servicesLoading" />
                                        <div v-else class="service-dropdown__content">
                                            <div class="service-dropdown__title">
                                                {{ $t(`addPolicyModal.${"chooseServiceTitle"}`) }}
                                            </div>

                                            <div class="service-dropdown__body">
                                                <div v-if="!dropdownServices.length" class="service-dropdown__no-data">
                                                    No services found
                                                </div>

                                                <template v-else>
                                                    <div class="service-dropdown__item" v-for="service in dropdownServices" :key="service.id">
                                                        <div class="checkbox">
                                                            <input type="checkbox" class="checkbox__checkbox" :checked="
                                    services.some((s) => s.id === service.id)
                                  " :id="service.id" @input="addOrRemoveServices($event, service)" />

                                                            <label :for="service.id" class="checkbox__label checkbox__label--smaller"></label>
                                                        </div>
                                                        <label :for="service.id" class="service-dropdown__item-title">
                                                            {{ service[`long_${language}`] }}
                                                        </label>
                                                    </div>
                                                </template>
                                            </div>

                                            <div class="service-dropdown__buttons">
                                                <button type="button" class="service-dropdown__button service-dropdown__button--submit" @click="applyServices()"></button>
                                                <button type="button" class="service-dropdown__button service-dropdown__button--cancel" @click="closeServicesDropdown()"></button>
                                            </div>
                                        </div>
                                    </div>
                                </transition>
                            </div>

                            <div class="services" v-if="services && showServices">
                                <Service v-for="service in services" :key="service.id" :service="service" :companyId="companyId" :deleteService="deleteService" :servicesToDelete="servicesToDelete" />
                            </div>
                        </div>
                    </div>
                </transition>
            </form>

            
        </div>
        <div v-if="isRequesting" class="modal__button modal__button--submit button button--default button--loading"></div>

        <div v-else style="width: 100%;">
            <div v-if="teamId == null" class="disabled-el"> 
                <button style="right:250px;  width: 220px;" disabled type="button" class="modal__button modal__button--submit button button--default" >
                    <span>{{ $t(`addPolicyModal.${"saveButtonOpenPendency"}`) }}</span>
                </button>

                <button type="button" disabled class="modal__button modal__button--submit button button--default">
                    <span>{{ $t(`addPolicyModal.${"saveButton"}`) }}</span>
                </button> 
            </div>

            <div v-else> 
                <button style="right:250px;  width: 220px;" type="button" class="modal__button modal__button--submit button button--default" @click="createPolicy(); continueToPendency = true;">
                    <span>{{ $t(`addPolicyModal.${"saveButtonOpenPendency"}`) }}</span>
                </button>

                <button type="button" class="modal__button modal__button--submit button button--default" @click="createPolicy()">
                    <span>{{ $t(`addPolicyModal.${"saveButton"}`) }}</span>
                </button>
            </div>
        </div> 
    </div> 
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import statics from "@/statics";
import Loading from "@/partials/Loading";
import Service from "@/components/AddPolicyModal/Service";
import DropFile from "@/components/DropFile";
import {
    parseCustomerName
} from "@/helpers/entityParsers";
import moment from "moment";
import parseFieldErrors from "@/helpers/parseFieldErrors";
export default {
    name: "AddPolicyModal",

    components: {
        Loading,
        Service,
        DropFile,
    },

    data() {
        return {
            teamIdHasError: false,
            teamId: null,

            continueToPendency: false,

            statics,
            KVG: statics.KVG,
            parseCustomerName,
            parseFieldErrors,
            currentPage: 0,
            showServicesDropdown: false,
            showServices: false,
            enableBrokerFirma: false,
            formLoading: false,
            isRequesting: false,
            servicesLoading: false,
            dropdownServices: [],
            companyId: 0,
            preServices: [],
            services: [],
            servicesToDelete: [],

            files: [],

            policy: {
                customerId: "",
                companyId: "",
                // agentId: JSON.parse(localStorage.getItem("user")).id,
                agentId: null,
                policyStatusId: "",
                policyNumber: "",
                policyMandate: {
                    companyId: "",
                },
                submissionDate: ""
            },
            policyNumberErrorPlaceHolder: "",
            companyHasError: false,
            agentHasError: false,
            statusHasError: false,
            policyNumberHasError: false,
            specificErrors: [],
        };
    },

    watch: {
        async companyId(id) {
            if (id) {
                this.companyHasError = false;
                this.showServices = true;
                this.servicesLoading = true;
                this.services = [];
                this.preServices = [];
                this.policy.companyId = id;

                if (this.formModels.length) {
                    this.$store.commit("setFormModels", []);
                }

                if (this.singleModel) {
                    this.$store.commit("setSingleModel", {
                        ...this.singleModel,
                        id: "",
                        serviceId: "",
                        startDate: "",
                        franchise: "",
                        accident: "",
                        doctorName: "",
                        price: "",
                        interval: "",
                        startDateHasError: false,
                        priceHasError: false,
                    });
                }

                if (this.singletonFormModels.length) {
                    this.$store.commit("setSingletonFormModels", []);
                }

                try {
                    this.dropdownServices = await this.$store.dispatch(
                        "getCompanyServices",
                        id
                    );
                } catch (err) {
                    console.error(err);
                }
                this.servicesLoading = false;
            } else {
                this.companyHasError = true;
                this.showServices = false;
            }
        },

        "policy.agentId"(id) {
            if (id) {
                this.agentHasError = false;
            } else {
                this.agentHasError = true;
            }
        },

        "policy.policyStatusId"(id) {
            if (id) {
                this.statusHasError = false;
            } else {
                this.statusHasError = true;
            }
        },
        specificErrors(errors) {
            const errorKeys = errors.map((error) => error.errorKey);

            if (errorKeys.includes("policy_number")) {

                this.policyNumberHasError = true;
                this.policyNumberErrorPlaceHolder = errors.find(
                    (error) => error.errorKey === "policy_number"
                ).errorValue;
            } else {
                this.policyNumberHasError = false;
                this.policyNumberErrorPlaceHolder = "";
            }

        },
    },

    computed: {
        ...mapGetters([
            "policyForms",
            "customerDetails",
            "formModels",
            "singleModel",
            "singletonFormModels",
            "servicesToRemove",
            "policyIsConverting",
            "convertingPolicyData",
            "customerMembers",
            "language",
            "teams"
        ]),

        fetchedTeams() {
            return this.teams;
        },

        agents() {
            return this.policyForms.agents;
        },

        companies() {
            return this.policyForms.companies;
        },

        brokerCompanies() {
            return this.policyForms["broker-companies"];
        },

        policyStatuses() {
            return this.policyForms["policy-statuses"];
        },

        salesCategoryId() {
            return this.policy.agentId ?
                this.agents.find((agent) => agent.id === this.policy.agentId)
                .sales_category_id :
                null;
        },
    },

    destroyed() {
        this.$store.commit("setPolicyIsConverting", false);
        this.$store.commit("setConvertingPolicyData", {});
    },

    async created() {
        this.formLoading = true;

        await this.$store.dispatch("getTeams");

        // await this.$store.dispatch("getPolicyForms");
        //this.policy.customerId = this.customerDetails.id;

        // if (this.policyIsConverting) {
        //   this.policy.agentId = this.convertingPolicyData.agentId;
        //   this.policy.policyNumber = this.convertingPolicyData.text;
        // }

        if(this.customerDetails.team){
            this.teamId = Number(this.customerDetails.team.id);
            this.fetchPolicyData(this.teamId)
        }

        this.formLoading = false;
    },

    methods: {
        closeModal() {
            this.$store.commit("setShowAddPolicyModal", false);
            this.continueToPendency = false;
        },

        goBack() {
            this.currentPage--;
            if (this.currentPage < 0) {
                this.closeModal();
                this.$store.commit("setShowAddCustomerServicesModal", true);
            }
        },

        addOrRemoveServices(event, service) {
            const {
                checked
            } = event.target;
            const existingService = this.preServices.findIndex(
                (s) => s.id === service.id
            );
            if (checked) {
                if (existingService === -1) {
                    this.preServices = [...this.preServices, service];
                    this.servicesToDelete.splice(existingService, 1);
                    const serviceToRemoveExists = this.servicesToRemove.some(
                        (s) => s.id === service.id
                    );
                    if (serviceToRemoveExists) {
                        const filteredServices = this.servicesToRemove.filter(
                            (s) => s.id !== service.id
                        );
                        this.$store.commit("setServicesToRemove", filteredServices);
                    }
                }
            } else {
                this.servicesToDelete = [...this.servicesToDelete, service];
                this.preServices.splice(existingService, 1);
            }
        },

        applyServices() {
            this.services = [...this.preServices];

            if (this.servicesToRemove.length) {
                this.servicesToRemove.forEach((service) => {
                    if (service.type === "KVG") {
                        this.$store.commit("setSingleModel", {
                            id: "",
                            serviceId: "",
                            startDate: "",
                            franchise: "",
                            accident: "",
                            doctorName: "",
                            price: "",
                            interval: "",
                            startDateHasError: false,
                            priceHasError: false,
                        });
                    } else if (service.type === "multiple") {
                        this.$store.commit("setFormModels", []);
                    } else if (service.type === "singleton") {
                        const modelToDelete = this.singletonFormModels.findIndex(
                            (model) => model.serviceId === service.id
                        );
                        const singletonModels = [...this.singletonFormModels];
                        singletonModels.splice(modelToDelete, 1);
                        this.$store.commit("setSingletonFormModels", singletonModels);
                    }
                });
                this.servicesToDelete = [];
                this.$store.commit("setServicesToRemove", []);
            }

            // This line of code always keeps the KVG service at the beginning of the array
            const kvg = this.services.find((service) => service.id === this.KVG);
            if (kvg) {
                const indexOfKvg = this.services.findIndex(
                    (service) => service.id === this.KVG
                );
                this.services.splice(indexOfKvg, 1);
                this.services = [kvg, ...this.services];
                this.preServices = [...this.services];
            }

            this.showServicesDropdown = false;
        },

        closeServicesDropdown() {
            this.preServices = [...this.services];
            this.servicesToDelete = [];
            if (this.servicesToRemove.length) {
                this.$store.commit("setServicesToRemove", []);
            }
            this.showServicesDropdown = false;
        },

        deleteService(serviceId) {
            const serviceToDelete = this.services.findIndex(
                (service) => service.id === serviceId
            );
            this.services.splice(serviceToDelete, 1);
            this.preServices.splice(serviceToDelete, 1);
        },

        validatePolicyFields(callback) {
            const {
                agentId,
                companyId,
                policyStatusId
            } = this.policy;
            if (!companyId) {
                this.companyHasError = true;
            }
            if (!agentId) {
                this.agentHasError = true;
            }
            if (!policyStatusId) {
                this.statusHasError = true;
            }

            if (companyId && agentId && policyStatusId) {
                callback(true);
            } else {
                callback(false);
            }
        },

        validateModelFields(callback) {
            this.formModels.forEach((model, index) => {
                if (!model.startDate) {
                    this.$store.commit("setSpecificFormModel", {
                        index,
                        key: "startDateHasError",
                        data: true,
                    });
                } else {
                    this.$store.commit("setSpecificFormModel", {
                        index,
                        key: "startDateHasError",
                        data: false,
                    });
                }

                if (
                    model.endDate &&
                    moment(model.startDate).isAfter(moment(model.endDate))
                ) {
                    this.$store.commit("setSpecificFormModel", {
                        index,
                        key: "startDateHasError",
                        data: true,
                    });
                }

                if (
                    !model.price ||
                    model.price < 0 ||
                    statics.stringRegex.test(model.price) ||
                    statics.symbolRegex.test(model.price)
                ) {
                    this.$store.commit("setSpecificFormModel", {
                        index,
                        key: "priceHasError",
                        data: true,
                    });

                } else {
                    this.$store.commit("setSpecificFormModel", {
                        index,
                        key: "priceHasError",
                        data: false,
                    });
                }
            });

            this.singletonFormModels.forEach((model, index) => {
                if (!model.startDate) {
                    this.$store.commit("setSpecificSingletonFormModel", {
                        index,
                        key: "startDateHasError",
                        data: true,
                    });
                } else {
                    this.$store.commit("setSpecificSingletonFormModel", {
                        index,
                        key: "startDateHasError",
                        data: false,
                    });
                }

                if (
                    model.endDate &&
                    moment(model.startDate).isAfter(moment(model.endDate))
                ) {
                    this.$store.commit("setSpecificSingletonFormModel", {
                        index,
                        key: "startDateHasError",
                        data: true,
                    });
                }

                if (
                    (!model.price ||
                        model.price < 0 ||
                        statics.stringRegex.test(model.price) ||
                        statics.symbolRegex.test(model.price)) && model.serviceId != 32
                ) {
                    this.$store.commit("setSpecificSingletonFormModel", {
                        index,
                        key: "priceHasError",
                        data: true,
                    });

                } else {
                    this.$store.commit("setSpecificSingletonFormModel", {
                        index,
                        key: "priceHasError",
                        data: false,
                    });
                }
            });

            const hasKvg =
                this.services.findIndex((service) => service.id === this.KVG) !== -1;

            if (hasKvg) {
                if (!this.singleModel.startDate) {
                    this.$store.commit("setSpecificSingleModel", {
                        key: "startDateHasError",
                        data: true,
                    });
                } else {
                    this.$store.commit("setSpecificSingleModel", {
                        key: "startDateHasError",
                        data: false,
                    });
                }

                if (
                    moment(this.singleModel.startDate).isAfter(
                        moment(this.singleModel.endDate)
                    )
                ) {
                    this.$store.commit("setSpecificSingleModel", {
                        key: "startDateHasError",
                        data: true,
                    });
                }

                if (
                    !this.singleModel.price ||
                    this.singleModel.price < 0 ||
                    statics.stringRegex.test(this.singleModel.price) ||
                    statics.symbolRegex.test(this.singleModel.price)
                ) {
                    this.$store.commit("setSpecificSingleModel", {
                        key: "priceHasError",
                        data: true,
                    });
                } else {
                    this.$store.commit("setSpecificSingleModel", {
                        key: "priceHasError",
                        data: false,
                    });
                }
            }

            let models;
            if (hasKvg) {
                models = [
                    ...this.formModels,
                    ...this.singletonFormModels,
                    {
                        ...this.singleModel
                    },
                ];
            } else {
                models = [...this.formModels, ...this.singletonFormModels];
            }

            let allFieldsAreValid = models.every(
                (model) => !model.startDateHasError && !model.priceHasError
            );
            if (allFieldsAreValid) {
                callback(true);
            } else {
                callback(false);
            }
        },

        createPolicy() {
            this.isRequesting = true;
            const teamId = this.teamId;
            var policyIds = [];
            this.validatePolicyFields(async (policyState) => {
                this.validateModelFields(async (modelsState) => {
                    if (policyState && modelsState && this.services.length) {
                        let policyServices;
                        if (
                            this.services.findIndex((service) => service.id === this.KVG) ===
                            -1
                        ) {
                            policyServices = [
                                ...this.formModels,
                                ...this.singletonFormModels,
                            ].map((service) => ({
                                service_id: service.serviceId,
                                service_model_id: service.id,
                                start_date: service.startDate,
                                end_date: service.endDate,
                                premium_interval_id: service.interval,
                                frachise: service.franchise,
                                accident: service.accident,
                                premium: Number(service.price),
                                model_category_id: service.economy,
                                sales_category_id: this.salesCategoryId,
                            }));
                        } else {
                            policyServices = [
                                ...this.formModels,
                                ...this.singletonFormModels,
                                {
                                    ...this.singleModel
                                },
                            ].map((service) => ({
                                service_id: service.serviceId,
                                service_model_id: service.id,
                                start_date: service.startDate,
                                end_date: service.endDate,
                                premium_interval_id: service.interval,
                                frachise: service.franchise,
                                accident: service.accident,
                                premium: Number(service.price),
                                model_category_id: service.economy,
                                sales_category_id: this.salesCategoryId,
                            }));
                        }
                        const policy = {
                            ...this.policy,
                            policyServices, 
                            teamId
                        };

                        try {
                            const {
                                data
                            } = await this.$store.dispatch("addPolicy", policy);

                            policyIds = data.data;

                            this.$store.commit("setToast", {
                                display: true,
                                type: "success",
                                message: "Policy created successfully.",
                            });
                        } catch (err) {

                            console.log(err);
                            const {
                                errors
                            } = err.response.data;
                            this.specificErrors = this.parseFieldErrors(errors);
                            this.isRequesting = false;
                            // this.closeModal();
                            console.error(err);
                            this.$store.commit("setToast", {
                                display: true,
                                type: "error",
                                message: err.response.data.message,
                            });
                        }
                        if (policyIds.length) {
                            if (this.continueToPendency) {
                                const pendencyInfo = policyIds[0];

                                this.openAddNewPendency(pendencyInfo);
                            }

                            if (this.files.length) {
                                this.formLoading = true;
                                for (let i = 0; i < policyIds.length; i++) {
                                    const files = this.files.map((file) => file.file);

                                    let policyId = policyIds[i].id;
                                    await this.$store.dispatch("addPolicyFile", {
                                        policyId,
                                        files,
                                    });
                                }

                                this.formLoading = false;
                            }
                            if (this.policyIsConverting) {
                                await this.$store.dispatch(
                                    "deletePotentialPolicy",
                                    this.convertingPolicyData.id
                                );
                            }
                            await this.$store.dispatch(
                                "getGlobalCustomer",
                                this.customerDetails.id
                            );
                            this.closeModal();
                        }

                    } else {
                        this.isRequesting = false;
                    }
                });
            });
        },

        openAddNewPendency(policy) {
            this.$store.commit("setShowAddActivityModal", false);
            this.$store.commit("setShowAddPendencyModal", true);

            this.$store.commit("setFromPolicyId", policy.id);
            this.$store.commit("setFromPolicyAgentId", policy.agent.id);
        },

        async fetchPolicyData(id){
           
          this.$store.commit("setAgents", null);
          await this.$store.dispatch("getPolicyForms", id);
          this.policy.customerId = this.customerDetails.id;

          if (this.policyIsConverting) {
            this.policy.agentId = this.convertingPolicyData.agentId;
            this.policy.policyNumber = this.convertingPolicyData.text;
          }
        }
    },
};
</script>

<style lang="scss" scoped> 
.disabled-el{ 
    .mandat { 
        .checkbox{ 
            label{
                border: 1px solid #7F8FA480;
            }
        }
    }

    label {
        color: #7F8FA480 !important;
    } 
}

 

.policy {
    padding-bottom: 30px;
    box-shadow: none;

    &__form {
        display: flex;
        border-bottom: 1px solid var(--border-10);
        padding-bottom: 20px;
    }

    &__inputs {
        flex: 1;
        display: flex;
    }

    &__column {
        margin-right: 10px;
        flex: 1;

        .form__field {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .mandat {
        display: flex;
        align-items: center;
        margin: 31px 10px;

        &__title {
            color: var(--text-2);
            font-family: var(--muli-text-semi-bold);
            margin-right: 10px;
        }
    }

    ::v-deep &__service-toggler {
        position: relative;
        margin: 15px 0;
        padding-right: 20px;
        display: inline-flex;
        align-items: center;

        span {
            color: var(--text-2);
            font-size: var(--x-small);
            font-family: var(--muli-text-semi-bold);
        }
    }

    ::v-deep &__service-adder {
        width: 24px;
        height: 24px;
        background: var(--bg-2);
        margin-left: 22px;
        border-radius: var(--radius-3);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:before {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background: url("../assets/icons/default/add_icon.svg") no-repeat center;
            background-size: 15px;
            transition: all 0.3s ease;
        }

        &--active:before,
        &:hover:before {
            transform: rotate(180deg);
        }
    }

    ::v-deep .service-dropdown {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 100%;
        min-height: 265px;
        background: var(--bg-1);
        padding: 10px 20px;
        border: 1px solid var(--border-2);
        border-radius: var(--radius-1);
        box-shadow: 0px 3px 30px var(--shadow-4);
        width: 400px;
        transition: all 0.3s ease;

        &__title {
            color: var(--text-5);
            margin-bottom: 15px;
        }

        &__item {
            display: flex;
            align-items: center;
            font-size: var(--x-small);
            color: var(--text-2);
            padding: 6px 0;
        }

        &__item-title {
            margin-left: 10px;
        }

        &__content {
            position: relative;
        }

        &__body {
            max-height: 176px;
            overflow: auto;
            margin-bottom: 10px;
        }

        &__buttons {
            display: flex;
            justify-content: flex-end;
        }

        &__button {
            width: 44px;
            height: 27px;
            border-radius: var(--radius-1);

            &--submit {
                background-color: var(--bg-2);
                margin-right: 20px;
                background-image: url("../assets/icons/default/tick_icon.svg");
            }

            &--cancel {
                background: var(--bg-12);
                background-image: url("../assets/icons/default/x_icon_white.svg");
            }

            &--submit,
            &--cancel {
                background-repeat: no-repeat;
                background-size: 15px;
                background-position: center;
            }
        }
    }

    .services {
        border-top: 1px solid var(--border-10);
    }
}
</style>
