var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contracts"},[_c('div',{staticClass:"page__header"},[_c('div',{staticClass:"page__title"},[_vm._v(_vm._s(_vm.$t(("contracts." + ("title")))))]),_c('div',{staticClass:"page__right"},[_c('form',{staticClass:"search search--filter",on:{"submit":function($event){$event.preventDefault();return _vm.keywordsSearch()}}},[_c('div',{staticClass:"search__wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKeywords),expression:"searchKeywords"}],ref:"search",staticClass:"search__search",class:{ 'search__search--active': _vm.searchKeywords },attrs:{"type":"text"},domProps:{"value":(_vm.searchKeywords)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchKeywords=$event.target.value}}}),_c('div',{staticClass:"search__icon"}),_c('div',{staticClass:"search__splitter"})]),_c('div',{ref:"filterToggler",staticClass:"search__filter",on:{"click":function($event){_vm.showSearchFilter = !_vm.showSearchFilter}}}),(_vm.showSearchFilter)?_c('SearchFilter',{attrs:{"searchType":'contract',"filterToggler":_vm.$refs.filterToggler,"toggleSearchFilter":function (state) {
              _vm.showSearchFilter = state;
            }}}):_vm._e()],1)])]),_c('div',{staticClass:"customers__content"},[(_vm.policiesLoading)?_c('Loading'):_c('div',[(_vm.policiesPerPage.data.length == 0)?_c('NoData',{staticStyle:{"height":"80vh"},attrs:{"type":'contracts'}}):_c('div',{staticClass:"table"},[_c('div',{staticClass:"table__header box box--default"},[_c('div',{staticClass:"table__label table__label--small"}),_c('div',{staticClass:"table__label table__label--large"},[_vm._v(" "+_vm._s(_vm.$t(("contracts." + ("tableHead") + "." + ("insurance"))))+" ")]),_c('div',{staticClass:"table__label table__label--large"},[_vm._v(" "+_vm._s(_vm.$t(("contracts." + ("tableHead") + "." + ("company"))))+" ")]),_c('div',{staticClass:"table__label table__label--medium"},[_vm._v(" "+_vm._s(_vm.$t(("contracts." + ("tableHead") + "." + ("status"))))+" ")]),_c('div',{staticClass:"table__label table__label--large"},[_vm._v(" "+_vm._s(_vm.$t(("contracts." + ("tableHead") + "." + ("user"))))+" ")]),_c('div',{staticClass:"table__label table__label--medium"},[_vm._v(" "+_vm._s(_vm.$t(("contracts." + ("tableHead") + "." + ("policyNr"))))+" ")]),_c('div',{staticClass:"table__label table__label--medium"},[_vm._v(" "+_vm._s(_vm.$t(("contracts." + ("tableHead") + "." + ("created"))))+" ")]),_c('div',{staticClass:"table__label table__label--large"},[_vm._v(" "+_vm._s(_vm.$t(("contracts." + ("tableHead") + "." + ("agent"))))+" ")]),_c('div',{staticClass:"table__label table__label--small"}),_c('div',{staticClass:"table__label table__label--small"})]),_c('div',{staticClass:"table__body"},_vm._l((_vm.policiesPerPage.data),function(contract){return _c('div',{key:contract.id,staticClass:"row contract-row row--hover box box--default",class:[
              ("contract--" + (contract.policy_status.long_en)) ]},[_c('div',{staticClass:"row__data row__data--small contract-icon"},[_c('div',{class:[
                  'icon', 
                  ("icon__contract--" + (_vm.selectedPolicies[contract.id] 
                      ? contract.policy_services.find(function (s) { return s.service.branch_category.long_de === _vm.selectedPolicies[contract.id]; }).service.branch_category.id || 'krankenkasse'
                      : 'krankenkasse'))
                ]})]),_c('div',{staticClass:"row__data row__data--large"},[(contract.policy_services.length > 0)?_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[(contract.policy_services.length > 1)?_c('VSelect',{staticClass:"select select--default customer__dropdown",staticStyle:{"width":"100%","background":"0"},attrs:{"options":contract.policy_services.map(function (service) { return ({
                    label: service.service.branch_category.long_de,
                    value: service.service.branch_category.long_de
                  }); }),"label":"label"},model:{value:(_vm.selectedPolicies[contract.id]),callback:function ($$v) {_vm.$set(_vm.selectedPolicies, contract.id, $$v)},expression:"selectedPolicies[contract.id]"}}):_c('span',[_vm._v(" "+_vm._s(contract.policy_services[0].service.branch_category.long_de)+" ")])],1):_c('span',[_vm._v("------")])]),_c('div',{staticClass:"row__data row__data--name row__data--large"},[(contract.company)?_c('span',[_vm._v(_vm._s(contract.company.full_name))]):_c('span',[_vm._v("------")])]),_c('div',{staticClass:"row__data row__data--medium"},[(contract.policy_status)?_c('span',[_vm._v(_vm._s(contract.policy_status.name))]):_c('span',[_vm._v("------")])]),_c('div',{staticClass:"row__data row__data--name row__data--large"},[(contract.owner)?_c('span',[_vm._v(_vm._s(contract.owner.first_name)+" "+_vm._s(contract.owner.last_name))]):_c('span',[_vm._v("------")])]),_c('div',{staticClass:"row__data row__data--medium"},[(contract.policy_number)?_c('span',[_vm._v(_vm._s(contract.policy_number))]):_c('span',[_vm._v("------")])]),_c('div',{staticClass:"row__data row__data--medium"},[_vm._v(_vm._s(_vm.formatDate(contract.created_at)))]),_c('div',{staticClass:"row__data row__data--name row__data--large"},[(contract.agent)?_c('span',[_vm._v(" "+_vm._s(contract.agent.first_name)+" "+_vm._s(contract.agent.last_name)+" ")]):_c('span',[_vm._v("------")])]),_c('div',{staticClass:"row__data row__data--small row__data--pdf",on:{"click":function($event){return _vm.openContractFilesModal(contract.id, contract.policy_files)}}},[_c('div',{staticClass:"icon icon__pdf",class:{ 'icon__pdf--active': contract.policy_files.length }})]),_c('div',{staticClass:"row__data row__data--action row__data--small"})])}),0),_c('Pagination',{attrs:{"limit":3,"data":_vm.policiesPerPage},on:{"pagination-change-page":_vm.getResults}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }